import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

const storeModule = require('../../store/auction');

export default {
	name: 'AutoAuction',
	i18n: require('./i18n').default,
	init({store, context, route}) {
		const { to: { params: { autoId } = {} } = {} } = {} = route;

		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		store.registerModule('auction', storeModule);
		store.dispatch('auction/id', Number(autoId));
		store.dispatch('auction/getStoInfo');
		store.dispatch('auction/getBidsInfo');

		const { 'auction/preData': preData } = store.getters;
		return preData && preData.id ? preData : store.dispatch('auction/getPreData');
	},
	data() {
		return {
			userData: {},
			stoInfo: {},
			status: {
				new: 0,
				accepted: 1,
				visitToSto: 2,
				technicalReportReady: 4,
				auctionStart: 5,
				auctionEnd: 6,
				meetingWithBuyer: 7,
				dealHappened: 9,
				canceled: 10,
			},
			meta: {
				title: this.langId === 2 ? 'Статус заявки в Аукционе AUTO.RIA 24' : 'Статус заявки в Аукционі AUTO.RIA 24',
				desc: this.langId === 2 ? 'Обновление статусов вашей заявки, вся информация о прогрессе аукциона' : 'Оновлення статусів вашої заявки, вся основна інформація про прогрес аукціону',
			}
		};
	},
	mounted() {
		this.getUserData()
			.then((data) => {
				this.userData = data;
			});
	},
	methods: {
		...mapActions({
			getUserData: 'Common/userData',
		}),
		calendarDate(date) {
			return date ? moment(date).locale(this.langId === 2 ? 'ru' : 'uk').calendar() : '';
		}
	},
	computed: {
		...mapGetters({
			data: 'auction/preData',
			getStoInfo: 'auction/stoInfo',
			bidsInfo: 'auction/bidsInfo',
			autoId: 'auction/id',
		}),
		autoName() {
			return `${this.data.brand.name} ${this.data.model.name} ${this.data.year}`;
		},
		userName() {
			return this.userData.userId ? this.userData.userName : '';
		},
		auctionStatus() {
			return Number(this.data.auction.status.id);
		},
		isAuctionStarted() {
			const now = moment();
			const auctionStart = moment(this.data.auction.dates.start);
			return auctionStart.diff(now) <= 0;
		},
		photoLink() {
			return this.data.photos && this.data.photos[0] ? this.data.photos[0].link.f : 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg';
		},
		getStoMeetDate() {
			return this.calendarDate(this.getStoInfo && this.getStoInfo.schedulesInfo ? this.getStoInfo.schedulesInfo.arrivalDate : undefined);
		},
		getStoName() {
			return this.getStoInfo && this.getStoInfo.stoInfo ? this.getStoInfo.stoInfo.name : '';
		},
		getStoAddress() {
			return this.getStoInfo && this.getStoInfo.stoInfo ? this.getStoInfo.stoInfo.address : '';
		},
		getWinnerBidPrice() {
			return this.bidsInfo && this.bidsInfo.length > 0 ? this.bidsInfo[0].price : '';
		},
	},
	metaInfo() {
		return {
			title: this.meta.title,
			meta: [
				{name: 'description', content: this.meta.desc},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
		};
	},
};
