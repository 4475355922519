const translate = {
	messages: {
		ru: {
			'Спасибо': 'Спасибо',
			'Ваша заявка на участие в аукционе AUTO.RIA принята': 'Ваша заявка на участие в аукционе AUTO.RIA принята',
			'Что дальше': 'Что дальше',
			'Заявка принята': 'Заявка принята',
			'Менеджер свяжется с вами в течение 10 минут': 'Менеджер свяжется с вами в течение 10 минут',
			'и согласует с вами дату и время осмотра вашего авто на СТО': 'и согласует с вами дату и время осмотра вашего авто на СТО',
			'Визит на СТО': 'Визит на СТО',
			'Специалисты СТО проведут осмотр и сформируют отчет о техническом состоянии вашего авто': 'Специалисты СТО проведут осмотр и сформируют отчет о техническом состоянии вашего авто',
			'Старт аукциона': 'Старт аукциона',
			'Запланирован аукцион': 'Запланирован аукцион',
			'Разговор с менеджер состоялась': 'Разговор с менеджер состоялась',
			'по адресу': 'по адресу',
			'Проверено технически': 'Проверено технически',
			'Смотреть отчет СТО проверки': 'Смотреть отчет СТО проверки',
			'Начался онлайн аукцион': 'Начался онлайн аукцион',
			'Аукцион завершился': 'Аукцион завершился',
			'Менеджер сообщит вам о результатах проверки': 'Менеджер сообщит вам о результатах проверки. На основе технического отчета будет сформирована стартовая рыночная цена, с которой начнется аукцион по продаже вашего авто',
			'Ожидайте старта торгов! Мы напомним вам в день аукциона о его начале': 'Ожидайте старта торгов! Мы напомним вам в день аукциона о его начале',
			'Наблюдайте за аукционом на странице вашего авто': 'Наблюдайте за аукционом на странице вашего авто. Покупатели будут делать ставки в течение 2 часов, самая высокая цена будет предложена вам',
			'Перейти к предложению': 'Перейти к предложению',
			'наивысшая цена': 'наивысшая цена',
			'в аукционе за ваше авто': 'в аукционе за ваше авто. Вы можете продать авто по этой цене, тогда покупатель получит ваше авто, а вы – сумму, которая на 100% соответствует размеру ставки',
			'Вы согласились на наивысшую предложенную цену': 'Вы согласились на наивысшую предложенную цену',
			'Заявка отменена': 'Заявка отменена',
			'Спасибо за участие в аукционе AUTO.RIA24! Ваш автомобиль будет опуликовано на AUTO.RIA': 'Спасибо за участие в аукционе AUTO.RIA24! Ваш автомобиль будет опуликовано на AUTO.RIA',
			'Мои аукционы': 'Мои аукционы',
			'Встреча с покупателем': 'Встреча с покупателем',
			'По окончании аукциона вы встретитесь с покупателем который предложил наивысшую аукционную цену за ваше авто': 'По окончании аукциона вы встретитесь с покупателем который предложил наивысшую аукционную цену за ваше авто',
			'Если самая высокая цена вас устраивает - подготовьтесь к встрече с покупателем': 'Если самая высокая цена вас устраивает - подготовьтесь к встрече с покупателем',
			'Для оформления продажи вам понадобится': 'Для оформления продажи вам понадобится',
			'другие документы на авто при наличии': 'другие документы на авто при наличии',
			'Паспорт и ИНН': 'Паспорт и ИНН',
			'Сервисная книжка (при наличии)': 'Сервисная книжка (при наличии)',
			'Автомобильные ключи': 'Автомобильные ключи',
			'Страховой полис': 'Страховой полис',
			'Состоится переоформления авто и вы получите деньги': 'Состоится переоформления авто и вы получите деньги',
			'Статус аукциона': 'Статус аукциона',
			'Остались вопросы?': 'Остались вопросы?',
			'Готов проконсультировать': 'Готов проконсультировать',
			'Дмитрий Ищенко': 'Дмитрий Ищенко',
		},
		uk: {
			'Спасибо': 'Дякуємо',
			'Ваша заявка на участие в аукционе AUTO.RIA принята': 'Ваша заявка на участь у аукціоні AUTO.RIA прийнята',
			'Что дальше': 'Що далі',
			'Заявка принята': 'Заявка прийнята',
			'Менеджер свяжется с вами в течение 10 минут': 'Менеджер зв\'яжеться з вами протягом 10 хвилин',
			'и согласует с вами дату и время осмотра вашего авто на СТО': ' та узгодить з вами дату та час огляду вашого авто на СТО',
			'Визит на СТО': 'Візит на СТО',
			'Специалисты СТО проведут осмотр и сформируют отчет о техническом состоянии вашего авто': 'Спеціалісти СТО проведуть огляд та сформують звіт про технічний стан вашого авто',
			'Старт аукциона': 'Старт аукціону',
			'Запланирован аукцион': 'Запланирован аукцион',
			'Разговор с менеджер состоялась': 'Розмова з менеджер відбулась',
			'по адресу': 'за адресою',
			'Проверено технически': 'Перевірено технічно',
			'Смотреть отчет СТО проверки': 'Дивитися звіт СТО перевірки',
			'Начался онлайн аукцион': 'Розпочався онлайн аукціон',
			'Аукцион завершился': 'Завершився аукціон',
			'Менеджер сообщит вам о результатах проверки': 'Менеджер зателефонує вам щодо результатів перевірки. На основі технічного звіту буде сформована стартова ринкова ціна авто, з якої розпочнеться аукціон з продажу вашого авто',
			'Ожидайте старта торгов! Мы напомним вам в день аукциона о его начале': 'Очікуйте старту торгів! Ми нагадаємо вам у день аукціону про його початок',
			'Наблюдайте за аукционом на странице вашего авто': 'Спостерігайте за аукціоном на сторінці вашого авто. Покупці робитимуть ставки протягом 2 годин, найвища ціна буде запропонована вам',
			'Перейти к предложению': 'Перейти до пропозиції',
			'наивысшая цена': 'найвища ціна',
			'в аукционе за ваше авто': ' в аукціоні за ваше авто. Ви маєте змогу продати авто за цією ціной, тоді покупець отримає ваше авто, а ви — суму, яка на 100% відповідає розміру ставки',
			'Вы согласились на наивысшую предложенную цену': 'Ви погодились на найвищу запропоновану ціну',
			'Заявка отменена': 'Заявка скасована',
			'Спасибо за участие в аукционе AUTO.RIA24! Ваш автомобиль будет опуликовано на AUTO.RIA': 'Дякуємо за участь в аукціоні AUTO.RIA24! Ваше авто буде опуликовано на AUTO.RIA',
			'Мои аукционы': 'Мої аукціони',
			'Встреча с покупателем': 'Зустріч з покупцем',
			'По окончании аукциона вы встретитесь с покупателем который предложил наивысшую аукционную цену за ваше авто': 'По закінченню аукціону ви зустрінетесь з покупцем який запропонував найвищу аукціонну ціну за ваше авто',
			'Если самая высокая цена вас устраивает - подготовьтесь к встрече с покупателем': 'Якщо найвища ціна вас влаштовує – підготуйтесь до зустрічі з покупцем',
			'Для оформления продажи вам понадобится': 'Для оформлення продажу вам знадобиться',
			'другие документы на авто при наличии': 'інші документи на авто при наявності',
			'Паспорт и ИНН': 'Паспорт та ІПН',
			'Сервисная книжка (при наличии)': 'Сервісна книжка (при наявності)',
			'Автомобильные ключи': 'Автомобільні ключі',
			'Страховой полис': 'Страховий поліс',
			'Состоится переоформления авто и вы получите деньги': 'Відбудеться переоформлення авто та ви отримаєте гроші',
			'Статус аукциона': 'Статус аукціону',
			'Остались вопросы?': 'Залишилися питання?',
			'Готов проконсультировать': 'Готовий проконсультувати',
			'Дмитрий Ищенко': 'Дмитро Іщенко',
		}
	}
};
export default translate;
